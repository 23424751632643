.stories {
    display: block;
    position: relative;

    width: calc(100% - 8rem);
    max-width: 138rem;
    height: auto;

    margin: 0 auto;
    padding-bottom: 9rem;

    h2 {
        @include subheadingLarge;
        margin-bottom: 4rem;
    }


    .stories-grid {
        display: grid;
        position: relative;

        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-column-gap: 6rem;
        grid-row-gap: 6rem;


        .img-wrapper {

            position: relative;
            width: 100%;
            padding-top: calc(17 / 21 * 100%);
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .related-info {
            display: block;
            position: relative;

            width: 100%;
            height: auto;

            background-color: $lightOrange;
            text-align: left;

            p {
                @include small;

                padding-left: 3rem;
                padding-top: 4rem;

                text-transform: capitalize;
                font-weight: lighter;
            }

            h2 {
                @include standfirst;

                padding-left: 3rem;
                padding-bottom: 4rem;

                color: $purple;
                font-weight: normal;
                text-decoration: underline;
            }
        }

        .story-block:nth-of-type(1) {
            -ms-grid-column: 1;
            -ms-grid-column-span: 1;
            -ms-grid-row: 1;
            -ms-grid-row-span: 1;
        }

        .story-block:nth-of-type(2) {
            -ms-grid-column: 2;
            -ms-grid-column-span: 1;
            -ms-grid-row: 1;
            -ms-grid-row-span: 1;
        }

        .story-block:nth-of-type(3) {
            -ms-grid-column: 1;
            -ms-grid-column-span: 1;
            -ms-grid-row: 2;
            -ms-grid-row-span: 1;
        }

        .story-block:nth-of-type(4) {
            -ms-grid-column: 2;
            -ms-grid-column-span: 1;
            -ms-grid-row: 2;
            -ms-grid-row-span: 1;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .story-block:nth-of-type(1) {
                margin-right: 6rem;
            }
    
            .story-block:nth-of-type(3) {
                margin-right: 6rem;
            }
       }

       @supports (-ms-ime-align:auto) {
            .story-block:nth-of-type(1) {
                margin-right: 6rem;
            }

            .story-block:nth-of-type(3) {
                margin-right: 6rem;
            }
        }
    }
}

@media only screen and (max-width: 780px) {
    .stories {
        .stories-grid {
            display: grid;
            position: relative;
    
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            grid-row-gap: 6rem;
        }
    }
    
}

@media only screen and (max-width: 420px) {
    .stories {
        width: calc(100% - 4rem);
    }
}



.giftset-body {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 9rem;
    padding-bottom: 9rem;
    &:last-child {
        padding-bottom: 0;
    }
}
.giftset-section {
    display: block;
    position: relative;
    width: calc(100% - 8rem);
    max-width: 138rem;
    height: auto;
    margin: 0 auto;
    margin-bottom: 9rem;
    padding-top: 9rem;
    padding-bottom: 3rem;
    border-top: 2px solid $lightPurple;
    border-bottom: 2px solid $lightPurple;
    h2 {
        @include subheadingLarge;
        margin-top: -2rem;
        margin-bottom: 4rem;
    }
    .gift-block {
        box-shadow: 0 0 2px rgba($textBlack, .25);
        margin-bottom: 6rem;
    }
    .gift-option-radio {
        visibility: hidden;
        opacity: 0;
        display: none;
        &:checked+.gift-option-label {
            background-color: $darkOrange;
        }
    }
    .gift-option-label {
        display: block;
        width: 100%;
        box-sizing: border-box;
        cursor: pointer;
        padding: 3rem;
        background-color: $lightOrange;
        transition: background-color .3s ease-in-out; 
        &:hover {
            background-color: $darkOrange;
        }
    }
    .gift-image {
        padding-bottom: 2rem;
        img {
            display: block;
            max-width: 100%;
        }
    }
    .gift-title {
        @include standfirst;
        color: $purple;
        font-weight: normal;
        text-decoration: underline;
        padding-bottom: 2rem;
        // border-bottom: 2px solid $lightPurple;
    }
    .gift-description {
        @include small;
        text-decoration: none;
    }
    @media only screen and (min-width: 780px) {
        .giftset-options {
            display: flex;
            flex-wrap: wrap;
            .gift-block {
                display: flex;
                flex: 0 0 calc(50% - 3rem);
                max-width: calc(50% - 3rem);
                margin-right: 6rem;
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }
    @media only screen and (min-width: 960px) {
        .giftset-options {
            .gift-block {
                flex: 0 0 calc(33.333333% - 4rem);
                max-width: calc(33.333333% - 4rem);
                &:nth-child(2n) {
                    margin-right: 6rem;
                }
                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
    }
}
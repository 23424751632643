.freeform-embed {
    position: relative;
    @include body-content-width;
    .form-top-pos {
        position: absolute;
        top: -100px;
        visibility: hidden;
    }
    .form-divider {
        margin-top: 6rem;
    }
    .form-amount {
        @include subheading;
        font-weight: 700;
    }
    .freeform-row {
        margin-left: -15px;
        margin-right: -15px;
        h2 {
            @include subheadingLarge;
            margin-bottom: 4rem;
        }
        h3 {
            @include crossheading;
            margin-bottom: 3rem;
        }
        hr {
            border: none;
            border-top: 2px solid $lightPurple;
            margin: 4rem 0;
        }
        .button__submit {
            margin-bottom: 8rem;
            min-width: 32rem; //256px
        }
    }
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;  
  vertical-align: baseline;
}

* {
  outline-style:none;
  box-shadow:none;
  border-color:transparent;
}

html {
	font-size: 50%; // 8px baseline grid
	font-size: 8px; // 8px baseline grid	
	-webkit-text-size-adjust: none; // prevent iOS from resizing small text
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  overflow-x: hidden;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  text-decoration: none;
}

input {
  outline-style:none;
  box-shadow:none;
  border-color:transparent;
}

button:hover {
  cursor: pointer;
}

strong {
  font-weight: bold !important;
}
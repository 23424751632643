#donation-single-form {
    padding-top: 6rem;
}

.donation-form {
    display: block;
    position: relative;
    width: calc(100% - 4rem);
    max-width: 87.5rem;
    height: auto;
    margin: 0 auto;
    margin-bottom: 18rem;
    .form-header {
        @include subheading;
        margin-bottom: 2rem;
    }
    .indicator-grid {
        display: grid;
        position: relative;
        width: 100%;
        height: 2rem;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 1rem;
        margin-bottom: 6rem;
        .indicator {
            display: block;
            width: 100%;
            height: 100%;
            background-color: $lightPurple;
        }
        .indicator-on {
            display: block;
            width: 100%;
            height: 100%;
            background-color: $purple;
        }
        .indicator:nth-of-type(1) {
            -ms-grid-column: 1;
            -ms-grid-column-span: 1;
        }
        .indicator:nth-of-type(2) {
            -ms-grid-column: 2;
            -ms-grid-column-span: 1;
        }
        .indicator:nth-of-type(3) {
            -ms-grid-column: 3;
            -ms-grid-column-span: 1;
        }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .indicator:nth-of-type(1) {
                margin-right: 1rem;
            }
            .indicator:nth-of-type(2) {
                margin-right: 1rem;
            }
       }
       @supports (-ms-ime-align:auto) {
            .indicator:nth-of-type(1) {
                margin-right: 1rem;
            }

            .indicator:nth-of-type(2) {
                margin-right: 1rem;
            }
        }
    }
    form {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        label {
            display: block;
            @include body;
            font-weight: 400 !important;
            margin-bottom: 1rem !important;
            &.bold {
                font-weight: 700 !important;
            }
        }
        .freeform-input,
        input,
        select {
            @include body;
            width: calc(100% - 2rem);
            max-width: 58.5rem;
            //height: 5rem;
            background-color: $lightOrange;
            border: 1px solid $purple;
            border-radius: 1rem;
            padding-left: 1rem;
            margin-bottom: 2rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
        textarea {
            @include body;
            width: calc(100% - 2rem);
            max-width: 58.5rem;
            background-color: $lightOrange;
            border: 1px solid $purple;
            border-radius: 1rem;
            padding-left: 1rem;
            margin-bottom: 2rem;
        }
        select {
            height: auto !important;
        }
        .freeform-input:focus {
            background-color: white;
        }
        #form-input-message {
            height: 25.75rem;
        }
        #form-input-titleSelect {
            width: 17.5rem;
        }
        #form-input-city {
            width: 35rem;
        }
        #form-input-zipCode {
            width: 17.25rem;
            // margin-bottom: 9rem;
        }
        #form-input-homePhone {
            width: 40.75rem;
        }
        #form-input-county {
            width: 35rem;
        }
        #form-input-cardCVC {
            width: 17.25rem;
        }
        #form-input-validMonth,
        #form-input-validYear {
            width: 12rem;
        }
        #form-input-experiencedHomelessness {
            margin-bottom: 3rem;
        }
        .freeform-row {
            .freeform-submit {
                margin-top: 4rem;
                margin-bottom: 4rem;
                &:last
                div {
                    @include small;
                    font-weight: bold;
                    margin: 0;
                }
                button {
                    margin: 2rem 0 0;
                }
            }
            &:last-child {
                .freeform-submit {
                    margin-bottom: 0;
                }
            }
        }
        .checkbox-group {
            div {
                @include small;
                font-weight: bold;
                margin-bottom: 4rem;
            }
            input {
                width: 2.75rem;
                height: 2.75rem;
            }
            label {
                font-weight: lighter;
            }
            label:first-of-type {
                @include subheading;
                font-weight: unset;
                margin-bottom: 4rem;
            }
        }
        .input-group-one-line {
            margin-bottom: 2rem;
        }
    }
    .button, button {
        display: inline-block;
        position: relative;
        min-width: 32rem;
        // height: 8rem;
        margin-top: 5rem;
        @include body;
        background-color: $darkOrange;
        color: $purple;
        border: 2px solid $purple;
        border-radius: 1rem;
        padding: 12px 12px;
        box-sizing: border-box;
        text-align: center;

        &:hover {
            background-color: $purple;
            color: white;
            cursor: pointer;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: .2;
        }
    }

    .freeform-column--submit {
        padding-top: 5rem;
        padding-bottom: 1rem;

        .button, button {
            margin-top: 1rem;
            margin-bottom: 1rem;
            margin-right: 1rem;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .errors,
    .freeform-form-has-errors,
    .ff-errors,
    .ff-form-errors {
        @include small;
        color: $pink;
    }
    .ff-form-errors {
        margin: 0 0 6rem;
        p {
            margin: 0 0 2rem;
        }
    }
    .freeform-row .errors,
    .freeform-row .ff-errors,
    .freeform-row .freeform-form-has-errors {
        margin: -1.5rem 0 2rem;
        font-size: 2rem;
    }
    .ff-form-success {
        margin-bottom: 4rem;
    }

    label.freeform-required {
        &:after {
            content: " *";
            color: $pink;
        }
    }
    .donate_form_page_hidden {
        position: absolute;
        left: -50000px;
        top: -50000px;
        opacity: 0;
    }
    .gift-icon {
        display: block;
        position: relative;
        width: auto;
        height: 9rem;
        margin-bottom: 4rem;
    }
    .gift-info {
        display: block;
        position: relative;
        margin-bottom: 4rem;
        h2 {
            @include standfirst;
            font-weight: lighter;
            max-width: 76rem;
            margin-bottom: 4rem;
        }
        p {
            @include small;
            font-weight: bold;
        }
    }

    .gift-aid-label {
        position: relative;
        font-family: Helvetica,sans-serif;
        font-size: 2.25rem;
        line-height: 3rem;
        padding-left: 3.75rem;

        #form-input-giftAid {
            position: absolute;
            left: 0;
        }
    }

    #gift-aid {
        //display: inline;
        //width: 2rem;
        //height: 2rem;
        padding: 0;
        margin: 0;
        margin-right: 2rem;
    }
    #gift-aid-text {
        display: inline;
        @include small;
        font-weight: lighter;
        .text-block {
          display: inline-block;
          padding-left: 3.75rem;
          margin-top: -2.75rem;
        }
    }
    #gift-aid-link {
        display: block;
        @include small;
        font-weight: lighter;
        color: $purple;
        text-decoration: underline;

        margin-top: 4rem;
    }
    .form-other {
        display: none;
    }

    // freeform styling 
    .freeform-row {
        margin-left: -15px;
        margin-right: -15px;
        input[type=radio], input[type=checkbox] {
            top: auto;
            width: 3rem;
            height: 3rem !important;
            margin: 0 1rem 0 0;
            vertical-align: -4px;
            background-color: $lightOrange;
        }
        input[type=radio] {
            border-radius: 50%;
            border: 2px solid $purple;
        }
    }

    .donation-info {
        width: auto;
        margin-bottom: 6rem;

        input {
            @include subheading;
            width: 12rem;
            margin-left: 1rem;
            margin-right: 1rem;
            
            &:read-only {
                background-color: $lightBlue;
                border-color: $lightPurple;
                cursor: not-allowed;
            }
        }
    }
}

#form_2 form {
    margin-bottom: 9rem;
}

#form_2 .freeform-row:nth-of-type(4) {
    display: grid;
    grid-template-columns: 14rem 14rem 38rem;

    .freeform-column:nth-of-type(3) {
        justify-self: end;
    }

    .freeform-column:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-column-span: 1;
        grid-column: 1;
    }

    .freeform-column:nth-of-type(2) {
        -ms-grid-column: 2;
        -ms-grid-column-span: 1;
        grid-column: 2;
    }

    .freeform-column:nth-of-type(3) {
        -ms-grid-column: 3;
        -ms-grid-column-span: 1;
        grid-column: 3;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .freeform-column:nth-of-type(1) {
            margin-right: 1rem;
        }

        .freeform-column:nth-of-type(2) {
            margin-right: 1rem;
        }
   }

   @supports (-ms-ime-align:auto) {
        freeform-column:nth-of-type(1) {
            margin-right: 1rem;
        }

        .freeform-column:nth-of-type(2) {
            margin-right: 1rem;
        }
    }
}

@media only screen and (max-width: 600px) {
    #form_2 .freeform-row:nth-of-type(4) {
        display: grid;
        grid-template-columns: 14rem 14rem;

        .freeform-column:nth-of-type(3) {
            justify-self: unset;
        }
    }
}


.subheading {
    @include body-content-width;
    display: block;
    position: relative;
    height: auto;
    margin-bottom: 4rem;
    p {
        @include subheading;
        color: $textBlack;
        margin-bottom: 4rem;
        font-weight: lighter;
    }
}

@media only screen and (max-width: 420px) {
    .subheading {
        width: calc(100% - 4rem);
    }
}
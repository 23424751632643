.video {
  @include body-content-width;
  display: block;
  position: relative;
  height: auto;
  padding-bottom: 4rem;
  .video-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 0px;
    padding-bottom: 56.25%;
    margin-bottom: 5rem;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

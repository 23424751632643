.button-link {
    @include body-content-width;
    padding-bottom: 9rem;
    .button {
        position: relative;
        display: inline-block;
        min-width: 25rem; //200px
        padding: 2rem;
        box-sizing: border-box;
        @include body;
        text-align: center;
        background-color: $darkOrange;
        color: $purple;
        border: 2px solid $purple;
        border-radius: 1rem;
        box-shadow: none;
        &:hover {
            background-color: $purple;
            color: white;
        }
        &:disabled {
            cursor: not-allowed;
            opacity: .2;
        }
    }
    &--left {
        text-align: left;
    }
    &--center {
        text-align: center;
    }
    &--right {
        text-align: right;
    }

    &--multi {
        margin-top: -1.25rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &--centreAligned {
            justify-content: center;
        }

        .button {
            margin-right: 1rem;
            margin-top: 1.25rem;
            min-width: 0;
        }
    }
}
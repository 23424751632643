.services {
    display: block;
    position: relative;

    width: calc(100% - 8rem);
    max-width: 138rem;
    height: auto;

    margin: 0 auto;
    margin-bottom: 9rem;

    padding-top: 4rem;
    padding-bottom: 4rem;

    border-top: 2px solid $lightPurple;
    border-bottom: 2px solid $lightPurple;

    h2 {
        @include subheadingLarge;
        margin-bottom: 4rem;
    }

    .services-grid {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        height: auto;

        .service-block {
          width: 31.5%;
          margin-right: 2.75%;
          &:nth-of-type(3n) {
            margin-right: 0;
          }
        }

        .img-wrapper {
            position: relative;
            width: 100%;
            padding-top: calc(17 / 21 * 100%);
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .related-info {
            display: block;
            position: relative;
            width: 100%;
            height: auto;
            background-color: $lightOrange;
            text-align: left;
            p {
                @include small;
                padding-left: 3rem;
                padding-top: 4rem;
                text-transform: capitalize;
                font-weight: lighter;
            }
            h2 {
                @include standfirst;
                padding-left: 3rem;
                padding-top: 4rem;
                padding-bottom: 4rem;
                a {
                    color: $purple;
                    font-weight: normal;
                    text-decoration: underline;
                }
            }
        }
    }
}
.services-mobile {
    display: none;
    position: relative;
    width: 100%;
    overflow: hidden;
    h2 {
        @include subheadingLarge;
        margin-bottom: 4rem;
        padding: 0 3rem;
    }
    .services-grid {
        .img-wrapper {

            position: relative;
            width: 100%;
            padding-top: calc(17 / 21 * 100%);
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .related-info {
            display: block;
            position: relative;

            width: 100%;
            height: auto;

            background-color: $lightOrange;
            text-align: left;

            p {
                @include small;

                padding-left: 3rem;
                padding-top: 4rem;

                text-transform: capitalize;
                font-weight: lighter;
            }

            h2 {
                @include standfirst;

                padding-left: 3rem;
                padding-top: 4rem;
                padding-bottom: 4rem;

                color: $purple;
                font-weight: normal;
                text-decoration: underline;
            }
        }
    }
}

.services ~ .services {
    border-top: none;
    margin-top: -9rem;
}
@media only screen and (max-width: 1099px) {
    .services {
        display: none;
    }
    .services-mobile {
        display: block;

        .services-border {
            display: block;
            width: calc(100% - 8rem);
            max-width: 138rem;
            height: 2px;

            margin: 0 auto;
            margin-bottom: 7rem;
            background-color: $lightPurple;
        }

        .services-grid-mobile {
            display: block;
            flex-wrap: wrap;
            position: relative;

            .service-block {
                display: block;
                width: 100%;
                padding: 0 3rem;
            }

            .img-wrapper {

                position: relative;
                width: 100%;
                padding-top: calc(17 / 21 * 100%);
                overflow: hidden;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .related-info {
                display: block;
                position: relative;

                width: 100%;
                height: auto;

                background-color: $lightOrange;
                text-align: left;

                p {
                    @include small;

                    padding-left: 3rem;
                    padding-top: 4rem;

                    text-transform: capitalize;
                    font-weight: lighter;
                }

                h2 {
                    @include standfirst;

                    padding-left: 3rem;
                    padding-top: 4rem;
                    padding-bottom: 4rem;

                    color: $purple;
                    font-weight: normal;
                    text-decoration: underline;
                }
            }
        }
    }

    .services-mobile ~ .services-mobile {
        margin-top: -7rem;

        .services-border:first-child {
            background-color: transparent;
        }
    }
}

// @media only screen and (max-width: 420px) {
//     .services {
//         width: calc(100% - 4rem);
//     }
// }

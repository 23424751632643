.info-icons {
    display: block;
    position: relative;
    text-align: center;

    width: calc(100% - 8rem);
    max-width: 138rem;
    height: auto;

    margin: 0 auto;
    margin-bottom: 9rem;

    padding-top: 9rem;
    padding-bottom: 9rem;
    border-top: 2px solid $lightPurple;
    border-bottom: 2px solid $lightPurple;

    .info-grid {
        display: grid;
        position: relative;

        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 5.75rem;

        .info-block {
            text-align: center;
            align-self: center;

            .icon {
                width: auto;
                height: 26rem;
                margin-bottom: 4rem;
            }

            p {
                @include body;
            }
        }

        .info-block:nth-of-type(1) {
            -ms-grid-column: 1;
            -ms-grid-column-span: 1;
        }

        .info-block:nth-of-type(2) {
            -ms-grid-column: 2;
            -ms-grid-column-span: 1;
        }

        .info-block:nth-of-type(3) {
            -ms-grid-column: 3;
            -ms-grid-column-span: 1;
        }

    }

}

@media only screen and (max-width: 780px) {
    .info-icons {
        display: block;
        position: relative;
        text-align: center;

        width: calc(100% - 8rem);
        max-width: 138rem;
        height: auto;

        margin: 0 auto;
        margin-bottom: 9rem;

        padding-top: 9rem;
        padding-bottom: 9rem;
        border-top: 2px solid $lightPurple;
        border-bottom: 2px solid $lightPurple;

        .info-grid {
            display: grid;
            position: relative;

            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-row-gap: 6rem;

            .info-block {
                p {
                    max-width: 42rem;
                    margin: 0 auto;
                }
            }
        }

    }
}


@media only screen and (max-width: 420px) {
    .info-icons {
        width: calc(100% - 4rem);
    }
}

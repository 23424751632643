.hero-banner {
  position: relative;
  background-color: $darkOrange;
  font-family: Helvetica,sans-serif;
  font-size: 1rem;
  line-height: 5rem;
  font-weight: lighter;
  box-shadow: 0 2px 3px rgba($textBlack, .25);
  z-index: 1;
  .wrapper {
    display: block;
    width: calc(100% - 4rem);
    max-width: 138rem;
    margin: 0 auto;
    padding: 2rem 0;
    h3 {
      font-size: 3rem;
      font-weight: 400;
    }
    a {
      display: inline-block;
      position: relative;
      padding: 1rem 2rem;
      @include body;
      font-weight: 400;
      background-color: $darkOrange;
      color: $purple;
      border: 2px solid $purple;
      border-radius: 1rem;
      &:hover,
      &:focus {
        background-color: $purple;
        color: white;
      }
    }
    @media only screen and (min-width: 420px) {
      width: calc(100% - 8rem);
    }
    @media only screen and (min-width: 960px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.owl-dots {
    display: inline-block;
	text-align: center;
	line-height: 1rem;
    margin-bottom: 7rem;

	.owl-dot {
		display: inline-block;
		padding: .5rem;
		margin: -.5rem 0; // cancels out space caused by vertical padding
		span {
			display: block;
			width: 2.75rem;
			height: 2.75rem;
			border-radius: 50%;
			background: $purple
		}
		&.active span, &:hover span {
			background: $darkOrange;
        }

        span:hover {
            cursor: pointer;
        }
	}
}

// show carousels that have been disabled - based on http://stackoverflow.com/questions/28251644/disabling-owl-carousel-at-a-specific-viewport-width
.owl-carousel.off {
    display: block;
}

// equal height owl items – based on https://github.com/OwlCarousel2/OwlCarousel2/issues/378#issuecomment-314861156
.owl-stage {
	display: flex;
	flex-wrap: wrap;
}
.owl-item {
	display: flex;
	height: auto !important;
}

.owl-nav {
    @include visually-hidden();
}

.nav-prev,
.nav-next {
    border: solid $purple;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 0.8rem;
    margin-bottom: 3px;
}

.nav-prev:hover,
.nav-next:hover {
    cursor: pointer;
}

.nav-prev {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-right: 1rem;
}

.nav-next {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-left: 1rem;
}

.nav-wrap {
    display: table;
    position: relative;
    width: auto;
    height: auto;

    margin: 0 auto;
}

.hero-text {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    .hero-header {
        display: block;
        position: relative;
        bottom: 0;
        width: 100%;
        height: auto;
        background-color: $darkOrange;
        padding-bottom: 12rem;
        padding-top: 9rem;
        .text-container {
            @include body-content-width;
            display: block;
            position: relative;
            height: auto;
            h2 {
                @include standfirst;
                color: $textBlack;
                margin-bottom: 3rem;
                height: auto;
            }
            h1 {
                @include heading;
                height: auto;
            }
        }
        &__title-only {
            padding-bottom: 9rem;
        }
        &__no-background {
            background-color: transparent;
            border-top: 2px solid $darkOrange;
            padding-bottom: 0;
        }
    }
}

.stat-icons {
    display: block;
    position: relative;
    text-align: center;

    width: calc(100% - 8rem);
    max-width: 138rem;
    height: auto;

    margin: 0 auto;
    margin-bottom: 9rem;

    padding-top: 9rem;
    padding-bottom: 9rem;

    border-bottom: 2px solid $lightPurple;

    .stat-grid {
        display: grid;
        position: relative;

        height: auto;

        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 5.75rem;
        grid-auto-flow: column;

        .stat-block {
            text-align: center;
            align-self: auto;
            padding-bottom: 100%;
            margin-bottom: -100%;

            .icon {
                width: auto;
                height: 26rem;
                margin-bottom: 4rem;
            }

            p {
                @include body;
                font-weight: lighter;
            }

            .name {
                @include subheadingLarge;
                margin-bottom: 3rem;
            }

            .stat {
                @include heading;
                color: $pink;
                font-weight: lighter;
            }

            .desc {
                margin-bottom: 6rem;
            }

            button {
                display: block;
                position: absolute;
                bottom: 0;

                width: 19.25rem;
                height: 8rem;

                background-color: white;
                border: 2px solid $purple;
                border-radius: 1rem;

                @include donate-btn;
                color: $purple;
                font-weight: bold;

                margin-left: 11rem;
            }
        }

        .stat-block:nth-of-type(1) {
            -ms-grid-column: 1;
            -ms-grid-column-span: 1;
        }

        .stat-block:nth-of-type(2) {
            -ms-grid-column: 2;
            -ms-grid-column-span: 1;
        }

        .stat-block:nth-of-type(3) {
            -ms-grid-column: 3;
            -ms-grid-column-span: 1;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .stat-block:nth-of-type(1) {
                margin-right: 6rem;
            }

            .stat-block:nth-of-type(2) {
                margin-right: 6rem;
            }
       }

       @supports (-ms-ime-align:auto) {
            .stat-block:nth-of-type(1) {
                margin-right: 6rem;
            }

            .stat-block:nth-of-type(2) {
                margin-right: 6rem;
            }
        }


    }

}

@media only screen and (max-width: 880px) {
    .stat-icons {
        width: calc(100% - 8rem);
        max-width: 138rem;
        height: auto;

        .stat-grid {
            display: block;
            position: relative;

            .stat-block {
                p {
                    max-width: 42rem;
                    margin: 0 auto;
                }


                button {
                    margin-left: 0;

                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

    }
}


@media only screen and (max-width: 420px) {
    .stat-icons {
        width: calc(100% - 4rem);
    }
}

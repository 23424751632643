.news-by-categories-body {
    background-color: #fff;
    margin-top: -12rem;
    clip-path: polygon(0 12rem,100% 0,100% 100%,0 100%);
    padding-top: 12rem;
}

.filter-block {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    background-color: $lightPurple;
    margin-top: -9rem;
    margin-bottom: -12rem;
    clip-path: polygon(0 12rem,100% 0,100% 100%,0 100%);
    top: -12rem;
    h2 {
        @include subheadingLarge;
        margin-bottom: 2rem;
        padding-top: 9rem;
    }
    form {
        input {
            display: none;
        }
        label {
            display: inline-block;
            padding: 1rem 2rem;
            background-color: $darkOrange;
            border-radius: 1rem;
            margin-right: 2rem;
            margin-bottom: 2rem;
            @include body;
        }
        label:hover {
            background-color: $purple;
            color: white;
        }
        .filter-label-checked {
            background-color: $purple;
            color: white;
        }
        //radio button label style 
        input:checked + label {
            background-color: $purple;
            color: white;
        }
        label:hover {
            cursor: pointer;
        }
        .btn__filter-clear {
            display: inline-block;
            padding: .5rem 2rem;
            border: 2px solid $purple;
            border-radius: 1rem;
            margin-right: 2rem;
            margin-bottom: 2rem;
            @include body;
            color: $purple;
            &:hover {
                cursor: pointer;
                background-color: $purple;
                color: white;
            }
        }
        #form-submit {
            display: block;
            position: relative;
            margin-top: 4rem;
            width: 18rem;
            height: 8rem;
            background-color: transparent;
            border: 2px solid $purple;
            border-radius: 1rem;
            @include donate-btn;
            color: $purple;
        }
        #form-submit:hover {
            cursor: pointer;
            background-color: $purple;
            color: white;
        }
    }
    .clear-filters {
        position: relative;
        top: 4rem;
        @include body;
    }
}

.news-grid {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    width: 100%;

    .news-block {
        width: calc(33.3333% - 4rem);
        margin-right: 6rem;
        margin-bottom: 6rem;
        background-color: $darkOrange;
        box-shadow: 0 0 2px rgba($textBlack, .25);
        .img-wrapper {

            position: relative;
            width: 100%;
            padding-top: calc(17 / 21 * 100%);
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }


        .news-block-text {
            display: block;
            position: relative;

            padding-left: 2rem;
            padding-right: 2rem;
            padding-top: 3rem;

            height: auto;

            .type {
                @include small;
                text-transform: capitalize;
                margin-bottom: 1rem;
            }

            h2 {
                @include standfirst;
                color: $purple;
                font-weight: normal;
                text-decoration: underline;
                padding-bottom: 2rem;
            }

            .text {
                @include small;
                margin-bottom: 3rem;
            }

            .news-block-divider {
                display: block;
                position: relative;

                width: 100%;
                height: 1px;
                background-color: white;
            }

            .date {
                position: relative;

                @include small;
                color: $purple;
                margin-top: 2rem;
                margin-bottom: 3rem;
            }
        }
    }

    .news-block:nth-of-type(3n+0) {
        margin-right: 0rem;
    }
}

.more-entries {
    position: relative;
    margin-top: 4rem;
    a {
        position: relative;
        @include donate-btn;
        display: block;
        color: $purple;
        width: 25rem;
        height: 8rem;
        line-height: 8rem;
        margin: 0 auto;
        text-align: center;
        border: 2px solid $purple;
        border-radius: 1rem;
        background-color: transparent;
    }
}
.more-entries:hover {   
    a {
        color: white;
        background-color: $purple;
    }
}

@media only screen and (max-width: 960px) {
    .news-grid {
        display: grid;
        position: relative;

        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-row-gap: 6rem;

        .news-block {
            width: 100%;
            .news-block-text {
                .date {
                    position: relative;
                }
            }
        }
    }
}
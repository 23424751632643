.hero-donation {
    display: block;
    position: relative;

    width: 100%;
    overflow: hidden;
    padding: 7rem 0;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .hero-donation-block {
        display: block;

        // Don't include the padding in the width
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box; 

        width: 100%;
        height: auto;
        max-width: 78rem;

        background-color: $darkOrange;
        padding: 6rem 7.25rem;
        padding-bottom: 9rem;

        clip-path: polygon(0 0,100% 0,100% calc(100% - 3rem),0 100%);

        h1, h2 {
            @include subheadingLarge;
            margin-bottom: 4rem;
        }

        .donation-selector {
            display: grid;
            position: relative;

            grid-template-columns: 1fr 1fr;

            width: 100%;
            height: 8rem;

            border: 2px solid $purple;
            border-radius: 1rem;

            margin-bottom: 2rem;

            .option:first-of-type {
                border-right: 2px solid $purple;
            }

            .option {
                text-align: center;
                align-self: center;
                height: 8rem;

                p {
                    @include donate-btn;
                    transform: translateY(50%);
                    font-weight: bold;
                    color: $purple;
                }
            }

            .option:hover {
                cursor: pointer;
            }

            .option-selected {
                background-color: $purple;

                p {
                    color: white;
                }
            }

            .option:nth-of-type(1) {
                -ms-grid-column: 1;
                -ms-grid-column-span: 1;
            }

            .option:nth-of-type(2) {
                -ms-grid-column: 2;
                -ms-grid-column-span: 1;
            }
        }

        .tier {
            display: grid;
            position: relative;

            grid-template-columns: 27% 73%;
            grid-column-gap: 6rem;

            width: calc(100% - 4rem);
            min-height: 8rem;

            border: 2px solid $purple;
            border-radius: 1rem;

            margin-bottom: 2rem;

            padding: 2rem;

            .amount {
                align-self: top;
                -ms-grid-column: 1;
                -ms-grid-column-span: 1;
                margin: 1rem 6rem 0 0;

                p {
                    @include heading;
                    font-weight: bold;
                    color: $textBlack;
                    line-height: unset;
                }
            }

            .description {
                align-self: top;
                -ms-grid-column: 2;
                -ms-grid-column-span: 1;

                p {
                    @include body;
                    color: $textBlack;
                    width: calc(100% - 6rem);
                }
            }

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                .description {
                    padding-left: 6rem;
                }
            }

            @supports (-ms-ime-align:auto) {
                .description {
                    padding-left: 6rem;
                }
            }
        }

        .tier:hover {
            cursor: pointer;
        }

        .tier-selected {
            background-color: $purple;

            .amount {
                p {
                    color: white;
                }
            }

            .description {
                p {
                    color: white;
                }
            }
        }

        .other-amount {
            p {
                @include small;
                margin-bottom: 1rem;
            }

            input {
                @include subheading;

                width: 16rem;
                height: 5rem;

                background-color: $lightOrange;
                border: 1px solid $purple;
                border-radius: 1rem;

                padding-left: 1rem;
                margin-bottom: 4rem;
            }

            input:focus {
                background-color: white;
            }
        }

        .donate-btn {
            display: block;
            position: relative;

            width: 14rem;
            height: 8rem;

            background-color: $darkOrange;
            border: 2px solid $purple;
            border-radius: 1rem;

            p {
                @include donate-btn;
                color: black;

                position: absolute;
                text-align: center;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .donate-btn:hover {
            cursor: pointer;
            background-color: $purple;

            p {
                color: white;
            }
        }

        .placeholder {
            display: none;
        }
    }


}

.hero-donation__mobile-image {
    display: none;
}

.amount-description__mobile {
    display: none;
}

@media only screen and (max-width: 1200px) {
    .hero-donation {
        height: auto;

        img {
            width: 100%;
            height: auto;
            max-height: 56.25vw;
        }

        overflow: visible;

        .hero-donation-block {
            display: block;
            position: relative;

            background-color: $darkOrange;
            padding: 4rem;
            padding-bottom: 9rem;

            margin-left: auto;
            margin-right: auto;

            & > h1, & > h2 {
                box-sizing: border-box;
                padding-right: 0;
                margin-left: auto;
                margin-right: auto;
            }

            & > div {
                margin-left: auto;
                margin-right: auto;
            }

            .tier {
                display: grid;
                position: relative;

                grid-template-columns: 27% 73%;
                grid-column-gap: 6rem;

                height: auto;

                padding: 2rem;

                .amount {
                    align-self: start;
                }

                .description {
                    align-self: start;
                    width: calc(100% - 4rem);
                }
            }
        }
    }
}

@media only screen and (max-width: 680px) {
    .hero-donation__mobile-image {
        display: block;
        width: 100%;
    }

    .hero-donation {
        background-image: none !important;
        padding: 0;

        .hero-donation-block {
            max-width: 100%;
            
            .donation-selector {
                height: auto;

                .option {
                    height: auto;
                    padding: 1.5rem;

                    p {
                        line-height: 1;
                        margin: 0;
                        transform: none;
                    }
                }
            }

            & > h1, & > h2 {
                margin-bottom: 2rem;
                font-size: 2.5rem;
                line-height: 1.4;
            }

            .tier {
                display: flex;
                justify-content: center;
                flex-grow: 1;
                width: calc((100% / 3) - 38px);
                min-width: 100px;
                margin: 5px;
                min-height: auto;
                text-align: center;
                padding: 1.5rem;

                &.tier-selected {
                    &:after {
                        content: "";
                        display: block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 10px 10px 0 10px;
                        border-color: $purple transparent transparent transparent;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                    }
                }

                .amount {
                    margin: 0;
                }

                .amount p {
                    font-size: 3rem;
                    line-height: 1;
                }

                .description {
                    display: none;
                }
            }

            .option-content {
                display: flex;
                flex-wrap: wrap;
                margin: -5px;
                position: relative;
            }

            .other-amount {
                margin-top: 2rem;

                input {
                    width: 100%;
                    margin-bottom: 2rem;
                }
            }

            .donate-btn {
                width: 100%;
            }
        }
    }

    .amount-description__mobile {
        display: block;
        margin-top: 2rem;

        p {
            font-family: Helvetica,sans-serif;
            font-size: 2rem;
            line-height: 1.4;
        }

        p:not(.selected) {
            display: none;
        }
    }
}

@media only screen and (max-width: 600px) {
    .hero-donation {
        .hero-donation-block {
            .tier {
                display: block;
                .description {
                    width: 100%;
                    p {
                        width: 100%;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 681px) {
    .hero-donation-content {
        @include body-content-width;
        max-width: 138rem;
    }
}
.intro {
    display: block;
    position: relative;

    width: calc(100% - 8rem);
    max-width: 102rem;

    margin: 0 auto;
    padding-bottom: 9rem;

    text-align: center;

    .intro-header p {
        @include heading;
        margin-bottom: 4rem;
    }

    p {
        @include standfirst;
    }
}
.image {
    @include body-content-width;
    margin-bottom: 9rem;
    img {
        max-width: 100%;
    }
}

@media only screen and (max-width: 420px) {
    .image {
        width: calc(100% - 4rem);
    }
}

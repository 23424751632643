.related-news {
    display: block;
    position: relative;

    width: 100%;
    height: auto;

    background-color: white;

    padding-bottom: 9rem;
    margin-bottom: 9rem;

    .related-news-content {
        display: block;
        position: relative;

        width: calc(100% - 8rem);
        max-width: 138rem;
        height: auto;

        margin: 0 auto;
        z-index: 1;

        h2 {
            @include subheadingLarge;
            margin-bottom: 2rem;
        }

        .recent-news-grid {
            display: grid;
            position: relative;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 6rem;
            .news-block {
                background-color: white;
                .img-wrapper {
                    position: relative;
                    width: 100%;
                    padding-top: calc(17 / 21 * 100%);
                    overflow: hidden;
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .news-block-text {
                    padding-left: 2rem;
                    padding-right: 2rem;
                    padding-top: 3rem;

                    height: auto;
                    .type {
                        @include small;
                        text-transform: capitalize;
                        font-weight: lighter;
                    }
                    h2 {
                        @include standfirst;

                        color: $purple;
                        font-weight: normal;
                        text-decoration: underline;

                        padding-bottom: 2rem;
                        border-bottom: 2px solid $lightPurple;
                    }
                    .text {
                        @include small;
                        font-weight: lighter;
                        margin-bottom: 4rem;
                    }
                    .date {
                        position: relative;
                        bottom: 0;

                        @include small;
                        font-weight: lighter;
                        color: $purple;
                        margin-bottom: 4rem;
                    }
                }
            }
            .news-block:nth-of-type(1) {
                -ms-grid-column: 1;
                -ms-grid-column-span: 1;
            }

            .news-block:nth-of-type(2) {
                -ms-grid-column: 2;
                -ms-grid-column-span: 1;
            }

            .news-block:nth-of-type(3) {
                -ms-grid-column: 3;
                -ms-grid-column-span: 1;
            }

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                .news-block:nth-of-type(1) {
                    margin-right: 6rem;
                }

                .news-block:nth-of-type(2) {
                    margin-right: 6rem;
                }
           }

           @supports (-ms-ime-align:auto) {
                .news-block:nth-of-type(1) {
                    margin-right: 6rem;
                }

                .news-block:nth-of-type(2) {
                    margin-right: 6rem;
                }
            }
        }

        .button {
            display: block;
            text-align: center;
            padding: 12px 0;
            position: relative;

            width: 18.25rem;
            height: 8rem;
            box-sizing: border-box;

            left: 50%;
            transform: translateX(-50%);

            background-color: $purple;
            border: 2px solid white;
            border-radius: 1rem;
            color: white;

            @include donate-btn;

            margin-top: 4rem;

            &:hover {
                background-color: white;
                border: 2px solid white;
                color: $purple;
            }
        }

    }

    .background {
        width: 100%;
        height: calc(100% - 10rem);
        position: absolute;
        top: 10rem;
        left: 0;
        clip-path: polygon(0 8rem,100% 0,100% 100%,0 100%);
        background-color: $purple;
        z-index: 0;
    }
}

@media only screen and (max-width: 780px) {
    .related-news {

        .related-news-content {

            .recent-news-grid {
                display: grid;
                position: relative;

                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
                grid-row-gap: 6rem;

                .news-block {
                    .news-block-text {
                        .date {
                            position: relative;
                        }
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 420px) {
    .related-news {

        .related-news-content {
            width: calc(100% - 4rem);
        }
    }
}

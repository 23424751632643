.paragraphs {
    @include body-content-width;
    display: block;
    position: relative;
    height: auto;
    padding-bottom: 9rem;
    p,ul,span,ol {
        @include body;
        color: $textBlack;
        margin-bottom: 4rem;
        font-weight: lighter;
        list-style: unset;
    }
    p:last-of-type {
        margin-bottom: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        @include subheading;
        padding-bottom: 2rem;
    }

    h1 {
        font-size: 6rem;
    }

    h2 {
        font-size: 5rem;
    }

    h3 {
        font-size: 4rem;
    }

    h4 {
        font-size: 3rem;
    }

    h5 {
        font-size: 2rem;
    }

    h6 {
        font-size: 1rem;
    }

    figure {
        img {
            width: auto;
            height: auto;
            max-width: 100%;
        }
    }
    ol, ul {
        list-style: none;
        li {
            padding-left: 2.5rem;
            margin-bottom: .5rem;
            &:before {
                display: inline-block;
                margin-left: -2.5rem;
                min-width: 2.5rem;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    ul {
        li {
            &:before {
                content: '';
                width: 1.25rem;
                min-width: 1.25rem;
                margin-right: 1.25rem;
                margin-bottom: .25rem;
                height: 1.25rem;
                border-radius: 50%;
                background-color: $textBlack;
            }
        }
    }
    ol {
        counter-reset: dots;
        li {
            &:before {
                counter-increment: dots;
                content: counter(dots, decimal);
                font-weight: bold;                
            }
        }
    }
}

@media only screen and (max-width: 420px) {
    .paragraphs {
        width: calc(100% - 4rem);
    }
}

.accordion {
    padding-bottom: 9rem;

    &.accordion--closed {
        .icon-chevron {
            transform: rotate(0);
        }
    }
    .icon-chevron {
        transform: rotate(180deg);
    }

    .accordion__header {
        padding-bottom: 0;

        h4 {
            margin-bottom: 0;
            margin-top: 0;
            padding-bottom: 0;
        }
    
        button {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            text-align: left;
            padding: 2.4rem;
            background-color: $lightOrange;
            color: inherit;
            font-weight: inherit;
            line-height: inherit;
            cursor: pointer;
            border-radius: 0;
            border: 0;
        
            .accordion__icon {
                line-height: 1;
                font-size: 1px;
                flex-shrink: 0; // Stop the nested icon from shrinking
                margin-left: 1rem;
                margin-bottom: 0;

                .icon-chevron {
                    color: $purple;
                    width: 3.2rem;
                    height: 3.2rem;
                }
            }

            &:hover, &:focus {
                border-color: $purple;
                box-shadow: inset 0 0 0 2px $darkOrange; // h-offset v-offset blur spread
            }
        }

        @media screen and (min-width: 780px) {
            button {
                .accordion__icon {
                    .icon-chevron {
                        width: 4rem;
                        height: 4rem;
                    }
                }
            }
        }
    }
    
    .accordion__body {
        display: none;
        margin-top: 3rem;

        .paragraphs {
            padding-bottom: 0;
        }
    }
}
.donation-header {
    display: block;
    position: relative;
    width: 100%;
    height: 34rem;
    margin-bottom: 4rem;
    background-color: $darkOrange;
    clip-path: polygon(0% 0%,100% 0%,100% calc(100% - 12rem),0% 100%);
    .wrapper {
        display: block;
        position: relative;
        width: 100%;
        max-width: 138rem;
        height: 34rem;
        margin: 0 auto;
        padding: 64px 0 0;
        .back {
            @include small;
            position: relative;
            top: 0;
            color: $purple;
            text-decoration: underline;
            text-decoration-color: $textBlack;
            cursor: pointer;
            @media only screen and (max-width: 1160px) {
                margin-left: 2rem;
            }
        }
        h1 {
            @include heading;
            text-align: center;
            &.long-title {
                width: calc(100% - 4rem);
                max-width: 87.5rem;
                text-align: left;
                margin: 0 auto;
            }
        }
    }
}

.donation-info {
    display: block;
    position: relative;
    width: calc(100% - 4rem);
    max-width: 87.5rem;
    height: auto;
    margin: 0 auto;
    margin-bottom: 9rem;

    h2 {
        display: inline-block;
        @include subheading;
        margin: 0 0 24px;
    }

    input {
        @include subheading;
        width: 12rem;
        height: 5rem;
        background-color: $lightOrange;
        border: 1px solid $purple;
        border-radius: 1rem;
        padding-left: 1rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    input:focus {
        background-color: white;
    }

    .social {
        height: 32px;
        display: block;
        margin-bottom: 150px;

        .social-icon {
            display: inline-block;
            vertical-align: middle;
            width: 4rem;
            height: auto;
            margin-right: 2rem;
            
            path {
                fill: black !important;
            }
        }
    }
}

.form-divider {
    display: block;
    position: relative;
    //width: calc(100% - 4rem);
    max-width: 87.5rem;
    height: 2px;
    background-color: $lightPurple;
    margin: 0 auto;
    margin-bottom: 6rem;

    .donation-form & {
        margin-top: 2rem;
    }
}

#divider-top {
    margin-top: 9rem;
}

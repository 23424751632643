.donation-jump-link {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  border-top: 2px solid $lightPurple;
}

.donation-jump-link__adjacent-text {
  font-weight: bold;

  &.paragraphs {
    width: 100%;
    padding-bottom: 0;
    font-weight: 700;

    p {
      font-weight: 700;
    }
  }
}

@media screen and (min-width: 600px) {
  .donation-jump-link {
    flex-direction: row;
    align-items: flex-start;

    .button {
      flex-shrink: 0;
    }
  }

  .donation-jump-link__adjacent-text {
    flex-grow: 1;
    margin-right: 20px;
  }
}
.freeform-row {
    label {
        @include body;
        display: block;
        margin-bottom: 1rem;
        &.bold {
            font-weight: bold;
        }
    }
    .title {
        @include subheading;
        font-weight: unset;
        margin-top: 4rem;
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 4rem;
        }
    }
    p {
        @include body();
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 4rem;
        }
    }
    .input-group-one-line {
        display: flex;
        flex-wrap: wrap;
        label {
            margin-right: 2rem;
            padding-right: 10px;
        }
        input[type=radio], input[type=checkbox] {
            position: relative;
            // top: 1rem;
            // width: auto;
            // height: 4rem !important;
            display: inline-block;
            margin-right: 2rem;
        }
    }
    input, select, textarea {
        appearance:none;
        box-sizing: border-box;
    }
    input, select, textarea, .freeform-input {
        width: 100%;
        max-width: 52rem;
        background-color: #fff5cc;
        border: 1px solid gray;
        border-radius: 1rem;
        margin-bottom: 1rem;
        padding: 1rem 2rem;
        @include body;
        &.StripeElement {
            padding: 1.5rem 2rem;
            width: calc(100% - 4rem);
            max-width: calc(52rem - 4rem);
            &--invalid {
                border: 1px solid red;
            }
        }
    }
    input[type=radio], input[type=checkbox] {
        position: relative;
        width: 3rem;
        height: 3rem;
        padding: 0;
        margin: 0 1rem 0 0;
        vertical-align: -4px;
        background-color: $lightOrange;
        border: 2px solid $purple;
        &:checked {
            &:before {
                content: '';
                display: block;
                position: absolute;
                box-sizing: border-box;
            }
        }
    }
    input[type=radio] {        
        border-radius: 50%;
        &:checked {
            &:before {     
                top: .5rem;
                left: .5rem;
                width: 1.5rem;
                height: 1.5rem;
                background-color: $purple;           
                border-radius: 50%;
            }
        }
    }
    input[type=checkbox] {        
        border-radius: 0;
        &:checked {
            &:before {                
                transform: rotate(45deg);
                top: 0;
                left: .5rem;
                width: 1.375rem;
                height: 1.875rem;
                background-color: transparent;
                border-bottom: 3px solid $purple;
                border-right: 3px solid $purple;
                border-radius: 0;
            }
        }
    }
    select {
        background-image: url('../img/arrow-select.svg');
        background-size: 2.5rem; //20px
        background-repeat: no-repeat;
        background-position: right 1rem top 50%;
    }
    textarea {
        max-width: none;
    }
    button, .button, input[type=submit] {
        position: relative;
        display: inline-block;
        min-width: 15rem; //120px
        padding: 2rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        box-sizing: border-box;
        @include body;
        text-align: center;
        background-color: $darkOrange;
        color: $purple;
        border: 2px solid $purple;
        border-radius: 1rem;
        box-shadow: none;
        &:hover {
            background-color: $purple;
            color: white;
        }
        &:disabled {
            cursor: not-allowed;
            opacity: .2;
        }
    }
    .freeform-label {
        font-family: Helvetica,sans-serif;
        font-size: 2.5rem;
        // font-weight: bold;
        line-height: 4rem;
        color: rgba(0,0,0,.9);
        display: block;
        // margin-bottom: 2rem;
        &__large {
            @include subheadingLarge;
            margin-bottom: 4rem;
        }
        &__light {
            font-weight: normal;
            margin-bottom: 1rem;
        }
        &.freeform-required {
            &:after {
                content: ' *';
                color: red;
            }
        }
    }
    .freeform-column--html, .freeform-instructions {
        @include body;
        h2, h3, h4, p, ol, ul {
            margin-bottom: 2rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
        h2 {
            @include subheadingLarge;
        }
        h3 {
            @include standfirst;
        }
        h4 {
            font-weight: bold;
        }
        ol, ul {
            li {
                padding-left: 2.5rem;
                margin-bottom: .5rem;
                &:before {
                    display: inline-block;
                    margin-left: -2.5rem;
                    min-width: 2.5rem;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        ul {
            li {
                &:before {
                    content: '';
                    width: 1.25rem;
                    min-width: 1.25rem;
                    margin-right: 1.25rem;
                    margin-bottom: .25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: $textBlack;
                }
            }
        }
        ol {
            counter-reset: dots;
            li {
                &:before {
                    counter-increment: dots;
                    content: counter(dots, decimal);
                    font-weight: bold;                
                }
            }
        }
    }  
    .freeform-column--html {
        margin-bottom: 2rem;
    }
    .freeform-instructions {
        margin-bottom: 4rem;
    }
    .notice-board {
        padding: 2rem;
        color: $purple;
        background-color: $darkOrange;
        border-radius: 1rem;
        line-height: 1.25em;
        p {
            color: $purple;
            line-height: 1.25em;
        }
        ol, ul {
            li {
                padding-left: 2rem;
                &:before {
                    margin-left: -2rem;
                    min-width: 2rem;
                }
            }
        }
        ul li:before {
            width: 1rem;
            min-width: 1rem;
            height: 1rem;
            margin-right: 1rem;
            background-color: $purple;            
        }
        ol li:before {
            font-weight: normal;
        }
    }
    .freeform-column {
        flex: 1 0;
        padding: 12px 0;
        margin: 0 15px;
        box-sizing: border-box;
        &--checkbox_group, &--radio_group {
            >label {
                padding-left: 4rem;
                &.freeform-label {
                    padding-left: 0;
                }
                input[type=radio], input[type=checkbox] {
                    margin-left: -4rem;
                }
            }   
        }
        &--multiple_select {
            select {
                background-image: none;
            }
        }
    }
    @media only screen and (min-width: 681px) {
        display: flex;
    }
}
.freeform-form-success, .ff-form-success {
    @include body;
}
.freeform-form-has-errors, .ff-form-errors {
    @include body;
    color: red;
}
.freeform-errors, .ff-errors {
    @include small;
    color: red;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
.body.form-wrapper {
    margin-top: 0; //48px
    padding-bottom: 6rem;
    .freeform {
        padding-top: 6rem; //48px
    }
}
.form-wrapper {
    display: block;
    position: relative;
    width: calc(100% - 8rem);
    max-width: 76rem;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 420px) {
        width: calc(100% - 4rem);
    }
}
.content__form {
    border-top: 2px solid $darkOrange;
}

#form__serviceReview {
    .freeform-column {
        .button[name=form_previous_page_button], .button__prev {
            margin-right: 2rem;
            &:before {
                content: url('../img/arrow-left.svg');
                display: inline-block;
                margin-right: 1rem;
            }
            &:hover {
                &:before {
                    content: url('../img/arrow-left-white.svg');
                }
            }
        }
        .button[name=form_page_submit] {
            margin-right: 2rem;
            &:after {
                content: url('../img/arrow-right.svg');
                display: inline-block;
                margin-left: 1rem;
            }
            &:hover {
                &:after {
                    content: url('../img/arrow-right-white.svg');
                }
            }
        }
    }
}
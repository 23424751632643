.newsletter {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 auto;

    background-color: $darkOrange;

    padding-top: 16rem;
    padding-bottom: 9rem;

    margin-top: -9rem;

    clip-path: polygon(0 9rem,100% 0,100% 100%,0 100%);

    &.white-background {
        background-color: white;
        padding-top: 0;
        clip-path: none;
        margin-top: 0
    }

    .newsletter-block {
        @include body-content-width;
        display: block;
        position: relative;
        //padding-left: 4rem;
        //padding-right: 4rem;

        .content {
            display: block;
            position: relative;

            width: 100%;
            max-width: 54rem;
            height: auto;

            .header {
                height: auto;
                margin-bottom: 2rem;

                p {
                    @include subheadingLarge;
                }

            }

            .text {
                margin-bottom: 2rem;

                p {
                    @include standfirst;
                }
            }

            .form {

                label {
                    @include body;

                    display: block;
                    margin-bottom: 1rem;
                }

                input {
                    width: 100%;
                    max-width: 52rem;
                    // height: 5rem;
                    background-color: #fff5cc;
                    border: 1px solid gray;
                    border-radius: 1rem;

                    margin-bottom: 2rem;
                    padding-left: 1rem;

                    @include body;
                }

                textarea {
                    @include body;

                    width: calc(100% - 2rem);
                    max-width: 58.5rem;

                    background-color: $lightOrange;
                    border: 1px solid gray;
                    border-radius: 1rem;

                    padding-left: 1rem;

                    margin-bottom: 2rem;
                }

                input[type=radio],
                input[type=checkbox] {
                    position: relative;
                    top: 1rem;
                    width: auto;
                    display: inline-block;
                    margin-right: 2rem;
                }

                select {
                    border: 1px solid gray;
                    font-size: 2.5rem;
                    margin-bottom: 2rem;
                }

                #form-input-doesThePersonKnowYouveTalkedToUs {
                    background-image: none;
                }

                button, input[type=submit] {
                    display: block;
                    position: relative;

                    width: 17.25rem;
                    height: 8rem;
                    margin-top: 2rem;
                    margin-bottom: 2rem;

                    @include body;

                    background-color: $darkOrange;
                    color: $purple;
                    border: 2px solid $purple;
                    border-radius: 1rem;
                    box-shadow: none;
                }

                button:hover, input[type=submit]:hover {
                    background-color: $purple;
                    color: white;
                }

                .errors,
                .freeform-form-has-errors {
                    @include small;
                    color: $pink;
                }

                .freeform-row .errors,
                .freeform-row .freeform-form-has-errors {
                    margin: -1.5rem 0 2rem;
                    font-size: 2rem;
                }

                h3 {
                    @include subheading();
                    margin-top: 3rem;
                }

                p {
                    @include body();

                    &:last-child {
                        margin-bottom: 2rem;
                    }
                }

                .freeform-instructions {
                    @include body()
                }

                .BBFormSignupFieldWide {
                    float: none;
                    max-width: 32rem;

                    input {
                        // Don't include the padding in the width
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box; 
                    }
                }

                .BBFormSignupPrivacyLabelWide {
                    @include body;
                }

                .BBFormSignupPrivacyLinkWide {
                    @include body;
                    color: $purple;
                }
            }

            .input-group-one-line {
                display: flex;

                label {
                    margin-right: 2rem;
                }

                @media only screen and (max-width: 420px) {
                    display: block;

                    label {
                        margin-right: 0;
                    }
            }
          }
        }

        .image {
            display: block;
            position: absolute;

            width: 18rem;
            height: auto;

            top: 0;
            right: 4rem;

            img {
                width: 100%;
                height: auto;
            }

            @media only screen and (max-width: 680px) {
                display: none;
            }
        }
    }
}

.standfirst + .newsletter {
    margin-top: 0;
}

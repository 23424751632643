.hero-image--git-set-v2 {
  padding-top: 50%;
  background-position: center;
  background-size: cover;
}

.hero--git-set-v2 {
  padding-top: 0;
  .title {
    display: none;
    @include heading;
    height: auto;
    margin-bottom: 24px;
  }
  .standfirst {
    width: 100%;
    max-width: none;
    margin-bottom: 0;
    p {
      @include heading;
    }
  }

  @media only screen and (min-width: 780px) {
    .title {
      display: block;
    }
    
    .standfirst {
      p {
        @include standfirst;
        font-family: $aleo;
      }
    }
  }
  
  @media only screen and (min-width: 1201px) {   
    background-color: $darkOrange;

    .hero-image--git-set-v2 {
      position: absolute;
      padding-top: 0;
      top: 0;
      right: 0;
      width: calc((50% - 584px) + 600px);
      height: 100%;
    }
    
    .section-wrap {
      padding-bottom: 16rem;

      .text-container {
        width: calc(1104px - 600px);
      }
    }

    .skew-bg {
      position: absolute;
      right: 0;
      top: 100%;
      width: 100%;
      height: 16rem;
      transform: skewY(3deg);
      transform-origin: top right;
      background-color: white;     
    }
  }
}

.giftset-body-v2 {
  margin-top: 0;
}

.giftset-section-v2 {
  display: block;
  position: relative;
  width: calc(100% - 8rem);
  max-width: 138rem;
  height: auto;
  margin: 0 auto;
  margin-bottom: 9rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
  border-bottom: 2px solid $lightPurple;
  h2 {
      @include subheadingLarge;
      margin-top: -2rem;
      margin-bottom: 4rem;
  }
  .gift-block {
      box-shadow: 0 0 2px rgba($textBlack, .25);
      margin-bottom: 4rem;
    }
  .gift-block-border {
      display: flex;
      border: 1px solid $purple;
  }
  .gift-option-radio {
      visibility: hidden;
      opacity: 0;
      display: none;
      &:checked+.gift-option-label {
          background-color: $darkOrange;
      }
  }
  .gift-option-label {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      cursor: pointer;
      background-color: $lightOrange;
      transition: background-color .3s ease-in-out; 
      &:hover {
          background-color: $darkOrange;
      }
  }
  .gift-image {
      flex: 0 0 50%;
      max-width: 50%;
      img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
      }
  }
  .gift-info {
    padding: 2rem 1rem 2rem 2.5rem;
    color: $purple;
  }
  .gift-price {
      @include heading;
      color: $purple;
      font-weight: normal;
      padding-bottom: 0;
  }
  .gift-price-empty {
    @include subheading;
    color: $purple;
    line-height: 3.5rem;
    padding-bottom: 1rem;
  }
  .gift-description {
    @include body;
    line-height: 3rem;
    color: $purple;
    text-decoration: none;
  }
  @media only screen and (min-width: 780px) {
      .giftset-options {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .gift-block {
              display: flex;
              flex: 0 0 calc(50% - 2rem);
              max-width: calc(50% - 2rem);
              margin-right: 4rem;
              &:nth-child(2n) {
                  margin-right: 0;
              }
          }
          .gift-image {
            flex: 0 0 40%;
            max-width:40%;
          }
      }
  }
  @media only screen and (min-width: 1201px) {
      .giftset-options {
          .gift-block {
              flex: 0 0 calc(33.333333% - 2rem);
              max-width: calc(33.333333% - 2rem);
              margin-right: 3rem;
              margin-bottom: 3rem;
              &:nth-child(2n) {
                  margin-right: 3rem;
              }
              &:nth-child(3n) {
                  margin-right: 0;
              }
          }
      }
  }
}
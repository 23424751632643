.link__buttons {
    @include body-content-width;
    display: block;
    position: relative;
    height: auto;
    padding-bottom: 9rem;
    .button {
        position: relative;
        display: inline-block;
        min-width: 15rem; //120px
        padding: 2rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        box-sizing: border-box;
        @include body;
        text-align: center;
        background-color: $darkOrange;
        color: $purple;
        border: 2px solid $purple;
        border-radius: 1rem;
        box-shadow: none;
        &:hover {
            background-color: $purple;
            color: white;
        }
    }
}
.paragraphs {
    +.link__buttons {
        margin-top: -6rem;
    }
}
.html {
  @include body-content-width;
  .html-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 9rem;
  }
  iframe {
    width: 100%;
  }
}

.page-link {
    display: block;
    position: relative;
    text-align: center;

    width: calc(100% - 8rem);
    max-width: 138rem;
    height: auto;

    margin: 0 auto;
    margin-bottom: 9rem;

    clip-path: polygon(0 0,100% 0,100% 100% ,0 calc(100% - 3rem));
    background-color: $darkOrange;

    .page-link-grid {
        display: grid;
        position: relative;

        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 6rem;

        padding: 6rem;
        padding-bottom: 9rem;

        .left-col {
            position: relative;
            width: 100%;
            padding-top: 75%;
            overflow: hidden;

            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        .right-col {
            text-align: left;
            -ms-grid-column: 2;

            h2 {
                @include subheadingLarge;
                margin-bottom: 4rem;
            }

            p {
                @include small;
                font-weight: lighter;
                margin-bottom: 2rem;
            }

            a {
                @include small;
                font-weight: lighter;
                color: $purple;
                text-decoration: underline;

                display: block;
                margin-bottom: 1rem;
            }
        }
    }
}

@media only screen and (max-width: 780px) {
    .page-link {

        .page-link-grid {

            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-row-gap: 6rem;

            padding: 4rem;
            padding-bottom: 9rem;
        }
    }
}

@media only screen and (max-width: 420px) {
    .page-link {
        width: calc(100% - 4rem);
    }
}

.cookie-banner {
  position: fixed;
  top: 0;
  left: auto;
  right: 100%;
  bottom: 0;
  height: 100%;
  width: 90%;
  max-width: 500px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  background-color: $darkOrange;
  padding: 40px;
  box-sizing: border-box;
  @include body;
  transform: translateX(0%);
  transition: transform .5s ease, box-shadow .5s ease;
  overflow: auto;

  &.cookie-banner--open {
    transform: translateX(100%);
    box-shadow: 0 0 10px #000;
  }

  * {
    box-sizing: border-box;
  }

  h2 {
    @include subheading;
  }

  h3 {
    font-family: $aleo;
    font-size: 2.5rem;
    line-height: 3rem;
    color: $textBlack;
  }

  h2, p {
    margin-bottom: 16px;
  }

  h3 {
    margin-bottom: 8px;
  }

  button {
    border: 0;
    @include small;
    font-weight: bold;
    border-radius: 1rem;
    padding: 12px 24px;
    text-align: center;
    margin: 0;
    cursor: pointer;
  }
}

.cookie-banner__buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: -8px;
  margin-top: 24px;

  button {
    margin: 8px;
  }
}

#cookieButtonAccept,
#cookieButtonSet {
  background-color: $purple;
  color: #fff;

  &:hover {
    background-color: darken($purple, 10%);
  }
}

#cookieButtonManage {
  background-color: transparent;
  color: $purple;
  box-shadow: inset 0 0 0 2px $purple;

  &:hover {
    background-color: $purple;
    color: #fff;
  }
}

#cookieBannerPageTwo {
  display: none;
}

.cookie-banner__cookie-type-group {
  border-radius: 1rem;
  width: 100%;
  padding: 16px;
  background-color: #fff;
  margin-bottom: 12px;

  p {
    @include small;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.cookie-banner__cookie-type-group--essential {
  background-color: rgba(#000, .08);
}

.cookie-banner__choices {
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    + span {
      width: 28px;
      height: 28px;
      margin-right: 8px;
      box-shadow: inset 0 0 0 1px $purple;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:checked + span {
      background-image: url("/assets/img/check.svg");
    }
  }
}
.hero-banner--bottom {
    margin-top: -9.1rem;

    + footer {
        margin-top: 0;
    }
}

footer {
    display: block;
    position: relative;

    width: 100%;
    height: 53.75rem;
    background-color: black;

    margin-top: -9.1rem;

    .footer-content {
        display: block;
        position: relative;

        width: calc(100% - 8rem);
        max-width: 138rem;
        height: auto;

        margin: 0rem auto;
        padding-top: 4rem;

        #heading {
            @include subheadingLarge;
            color: white;
            margin-bottom: 4rem;
        }

        .main-logo {
            display: block;
            position: absolute;

            width: auto;
            max-width: 300px;
            height: 8rem;

            top: 4rem;
            right: 0;

        }

        .footer-social {
            height: 32px;
            display: block;

            margin-bottom: 4rem;

            .social-icon {
                display: inline-block;
                vertical-align: middle;

                width: 4rem;
                height: auto;

                margin-right: 2rem;
            }
        }

        .contact-info {
            display: block;
            margin-bottom: 2.75rem;

            a {
                display: inline-block;
                @include body;
                font-weight: lighter;
                color: white;
                vertical-align: bottom;
            }

            .divider {
                display: inline-block;
                width: 2px;
                height: 4rem;
                background-color: #565656;

                margin: 0 2rem;
            }
        }

        .footer-divider {
            display: block;
            width: 100%;
            height: 2px;
            background-color: #565656;
            margin-bottom: 2.75rem;

            &-mobile {
                display: none;
            }
        }

        .misc-links {
            display: block;
            position: relative;
            padding-bottom: 2.75rem;;

            a {
                display: inline-block;
                @include small;
                font-weight: lighter;
                color: white;
                vertical-align: bottom;
                margin-right: 2rem;
            }

            .divider {
                display: inline-block;
                width: 2px;
                height: 4rem;
                background-color: #565656;

                margin: 0 2rem;
            }
        }

        .foundraising-logo {
            display: block;
            position: relative;

            width: auto;
            height: 7rem;

            padding-bottom: 2.75rem;
        }

        .footer-misc {
            display: block;

            p {
                @include small;
                font-weight: lighter;
                color: white;
            }

            a {
                display: block;
                position: relative;

                @include small;
                font-size: 16px;
                font-weight: lighter;
                color: white;

                float: right;
                bottom: 6rem;
            }
        }
    }
}


@media only screen and (max-width: 1080px) {
    footer {
        .footer-content {
            .footer-misc {
                display: block;

                p {
                    @include small;
                    font-weight: lighter;
                    color: white;
                }

                .misc-links {
                    display: block;
                    position: relative;
                    right: 0;
                    bottom: 0;
                }

            }
        }
    }

}

@media only screen and (max-width: 780px) {
    footer {
        height: auto;
        .footer-content {

            .footer-divider-mobile {
                display: block;
            }

            .main-logo {
                display: block;
                position: relative;

                width: auto;
                height: 5rem;

                top: 0rem;
                right: 0;

                margin-bottom: 2.75rem;
            }

            .footer-misc {
                display: block;

                p {
                    @include small;
                    font-weight: lighter;
                    color: white;
                }

                a {
                    display: block;
                    position: relative;

                    font-size: 16px;
                    float: none;
                    bottom: 0;

                    margin-top: 2rem;
                    padding-bottom: 4rem;
                }

            }
        }
    }

}

@media only screen and (max-width: 510px) {
    footer {
        .footer-content {
            .contact-info {
                a {
                    display: block;
                }

                .divider {
                    display: none;
                }
            }
        }
    }

}

@media only screen and (max-width: 480px) {
    footer {
        height: auto;
        .footer-content {
            width: calc(100% - 4rem);
        }
    }

}

#ie-banner {
    @include large;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #283228;
	pointer-events: none;
	opacity: 0;
	transition: opacity .25s;
	color: #FFF;
	z-index: 100000000;
    .section-wrap {
        padding-top: 4rem;
        padding-bottom: 4rem;
        display: flex;
        .cookie-popup-left {
            flex-grow: 1;
        }
        .cookie-popup-right {
            text-align: right;
        }
    }
}

@media only screen and (max-width: 800px) {
    #cookie-popup {
        .section-wrap {
            display: block;
            .cookie-popup-right {
                text-align: left;
            }
        }
        #cookie-accept-btn {
            margin: 2rem 0 0;
        }
    }
}

#ie-banner {
    display: none;
    opacity: 1;
    background-color: $purple;
    box-shadow: 0 -2px 4px rgba(0,0,0,.2);
    h3 {
        color: $darkOrange;
        font-family: $aleo;
        font-size: 2.5rem;
        line-height: 4rem;
        letter-spacing: .0625rem;
    }
    p {
        font-size: 2rem;
        line-height: 3rem;
    }
}

.exit-button {
    position: fixed;
    bottom: 0;
    right: calc(50% - 584px);
    margin-right: 4rem;
    background-color: $purple;
    display: inline-block;
    color: #ffffff;
    padding: 1.375rem 2.125rem 1rem 2rem;
    border: 1px solid #ffffff;
    border-bottom: none;
    border-radius: 1rem 1rem 0 0;
    @include donate-btn;
    font-weight: bold;
    z-index: 10;
    a {
        color: #ffffff;
    }
    @media only screen and (max-width: 1168px) {
        right: 0;
    }
    @media only screen and (max-width: 779px) {
        display: none;
    }
}
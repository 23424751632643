$headerHeight: 16rem;
$mobileHeaderHeight: 10rem;

.content-body {
    position: relative;
    padding-top: 28rem;
}
#header {
    display: block;
    position: absolute;
    width: 100%;
    height: 28rem;
    z-index: 10;
    .top-header {
        display: block;
        position: relative;
        width: 100%;
        height: $headerHeight;
        background-color: $purple;
        .wrapper {
            width: 100%;
            max-width: 138rem;
            height: 100%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            .main-logo {
                display: block;
                width: auto;
                max-width: 300px;
                height: 64px;
            }
            .header-items {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            .header-contact {
                position: relative;
                margin-right: 4rem;
                svg {
                    width: auto;
                    max-width: 24px;
                    height: 26px;
                    margin-right: 1rem;
                }
                a {
                    @include standfirst;
                    color: white;
                }
            }
            .donate-btn {
                display: block;
                position: relative;
                padding: 2rem 4rem;
                background-color: $darkOrange;
                border-radius: 1rem;
                p {
                    @include donate-btn;
                    color: black;
                    text-align: center;
                }
            }
        }
    }
    .bottom-header {
        display: block;
        position: relative;
        width: 100%;
        height: 12rem;
        background-color: #ffffff;
        box-shadow: 0 1px 0 0 rgba(0,0,0,.15);
        .wrapper {
            width: 100%;
            max-width: 138rem;
            height: 100%;
            margin: 0 auto;
            position: static;
            .links {
                display: flex;
                position: static;
                width: 77.5%; // avoid menu overlapping search field
                height: 100%;
                float: left;
                align-items: center;
                .link {
                    display: inline-grid;
                    position: relative;
                    width: auto;
                    height: auto;
                }
                a {
                    display: inline;
                    // position: relative;
                    @include body;
                    line-height: 3.5rem;
                    vertical-align: bottom;
                }
                .link-divider {
                    display: inline-block;
                    height: 4rem;
                    width: 2px;

                    margin: 0rem 2rem;
                    background-color: #dadada;
                }
                .link-divider:nth-last-of-type(2) {
                    display: none;
                }
                .nav-item-active {
                    color: $purple;
                }
                .nav-item-active:after {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    position: absolute;
                    bottom: -4rem;
                    left: 50%;
                    margin-left: -2rem;
                    border-left: 2rem solid transparent;
                    border-right: 2rem solid transparent;
                    border-bottom: 2rem solid $darkOrange;
                }
                .grand-children {
                    display: none;
                    width: 100%;
                    padding-right: 2rem;
                    padding-bottom: 2rem;
                    a {
                        @include body;
                        display: block!important;
                        width: 100%;
                        font-weight: normal;
                        padding-left: 0rem;
                        padding-top: 1rem;
                        padding-bottom: 0rem;
                        border-left: none;
                        margin-bottom: 0rem;
                        border-bottom: none;
                    }
                }
            }
            .search {
                display: block;
                position: absolute;
                width: 30rem;
                height: auto;
                top: 50%;
                right: calc((100% - 138rem)/2);
                transform: translateY(-50%);
                float: right;
                form {
                    height: 100%;
                    width: auto;
                    input {
                        width: 30rem;
                        height: 5rem;
                        background-color: $lightOrange;
                        border: 1px solid gray;
                        border-radius: 1rem;
                        font-family: $default;
                        font-size: 2.5rem;
                        padding-left: 2rem;
                        padding-right: 2rem;
                        box-sizing: border-box;
                    }
                    input[type="submit"] {
                        display: none;
                    }
                    input:focus {
                        background-color: white;
                    }
                    svg {
                        position: absolute;
                        width: 25px;
                        height: auto;
                        top: 50%;
                        right: 1.5rem;
                        transform: translateY(-50%);
                    }
                    svg:hover {
                        cursor: pointer;
                    }
                }
            }
        }
        .info {
            display: none;
        }
        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100vw;
            height: auto;
            background-color: $darkOrange;
            box-shadow: 0 2px 3px rgba($textBlack, .25);
            z-index: 200;
            .sub-menu-wrap {
                width: calc(100% - 8rem);
                max-width: 138rem;
                padding: 4rem 4rem;
                margin: 0 auto;
                // .link:first-of-type a {
                //     padding-left: 0rem;
                //     border-left: none;
                // }
            }
            h2 {
                @include standfirst;
                margin-bottom: 4rem;
                font-weight: bold;
            }
            .link a {
                @include body;
                vertical-align: top!important;
                display: inline-block!important;
                width: 32rem;
                font-weight: bold;
                padding-left: 1rem;
                border-left: 2px solid white;
                margin-bottom: 2rem;
            }
            .no-padding {
                padding-left: 0rem !important;
                border-left: none !important;
            }

        }
    }
    .hamburger {
        display: none;
    }
    transition: top .3s ease-in-out;
    &.header__hide, &.header__show {
        position: fixed;
    }
    &.header__show {
        top: 0;
    }
    &.header__hide {
        top: -28rem;
    }
}
@media only screen and (max-width: 1200px) {
    .content-body {
        padding-top: $headerHeight;
    }
    #header {
        display: block;
        // position: relative;
        width: 100%;
        height: $headerHeight;
        .top-header {
            display: block;
            position: relative;
            width: 100%;
            height: $headerHeight;
            background-color: $purple;
            .wrapper {
                width: calc(100% - 8rem);
                max-width: 138rem;
                height: 100%;
                margin: 0 auto;
                .main-logo {
                    display: block;
                    position: absolute;
                    width: auto;
                    height: 64px;
                    top: 50%;
                    transform: translateY(-50%);
                    float: left;
                }
                .header-contact {
                    display: none;
                }
                .donate-btn {
                    margin-right: 2rem;
                    padding: 1rem 2rem;
                }
                .links {
                    .nav-item-active {
                        color: $purple;
                    }
                    .nav-item-active:after {
                        display: none;
                    }
                }
            }
        }
        .bottom-header {
            display: block;
            position: absolute;
            height: calc(100vh - #{$headerHeight});
            overflow: scroll;
            background-color: $purple;
            z-index: 10;
            .wrapper {
                max-width: unset;
                position: static;
               .links {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: auto;
                    float: none;
                    align-items: center;
                    background-color: #ffffff;
                    .link {
                        display: inline;
                    }
                    a {
                        display: block;
                        @include body;
                        padding: 2rem 4rem;
                        border-bottom: 2px solid $lightPurple;
                        position: relative;
                    }
                    .link-divider {
                        display: none;
                    }
                    .nav-item-active:after {
                        display: none;
                    }
                    .sub-menu {
                        display: none;
                        position: relative;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: auto;
                        background-color: $darkOrange;
                        z-index: 200;
                        .sub-menu-wrap {
                            width: calc(100% - 8rem);
                            max-width: unset;
                            padding: 0 4rem;
                            margin: 0;
                            h2 {
                                margin-bottom: 0;
                            }
                            a {
                                @include body;
                                display: block!important;
                                width: calc(100% - 4rem);
                                font-weight: bold;
                                padding-left: 0rem;
                                border-left: none;
                                margin-bottom: 0rem;
                                border-bottom: 2px solid white;
                            }
                            .link:last-of-type a {
                                border-bottom: none;
                            }
                            .no-padding {
                                padding-left: 0rem;
                                border-left: none;
                            }
                            .no-border {
                                border-bottom: none;
                                padding-bottom: 0rem;
                            }
                            .grand-children {
                                display: none;
                                width: 100%;
                                padding-right: 2rem;
                                padding-bottom: 2rem;
                                border-bottom: 2px solid white;
                                a {
                                    @include body;
                                    display: block!important;
                                    width: 100%;
                                    font-weight: normal;
                                    padding-left: 0rem;
                                    padding-top: 1rem;
                                    padding-bottom: 0rem;
                                    border-left: none;
                                    margin-bottom: 0rem;
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
                .search {
                    display: none;
                }
            }
            .info {
                position: relative;
                display: block;
                height: auto;
                padding: 2rem 4rem 15rem;
                font-size: 3rem;
                line-height: 4.5rem;
                a {
                    font-family: Helvetica,sans-serif;
                    font-size: 2.5rem;
                    line-height: 4rem;
                }
                .contact {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-bottom: 2rem;
                    a {
                        color: #ffffff;
                    }
                    .icon {
                        width: auto;
                        height: 26px;
                        margin-right: 1rem;
                    }
                }
                .donate-btn {
                    padding: 2rem 3rem;
                    width: 14rem;
                    background-color: $darkOrange;
                    border-radius: 1rem;
                    color: black;
                    text-align: center;
                    font-weight: 400;
                }
                .link-divider {
                    display: block;
                    position: relative;
                    height: 2px;
                    margin: 0 auto;
                    margin-top: 2rem;
                    background-color: #fff;
                }
                .search {
                    display: block;
                    position: relative;
                    width: 34rem;
                    height: auto;
                    margin: 2rem 0;
                    top: unset;
                    right: unset;
                    transform: none;
                    float: unset;
                    form {
                        height: 100%;
                        width: auto;
                        input {
                            width: 32rem;
                            height: 5rem;
                            background-color: $lightOrange;
                            border: 1px solid gray;
                            border-radius: 1rem;
                            font-family: $default;
                            font-size: 2.5rem;
                            padding-left: 2rem;
                            padding-right: 1rem;
                            box-sizing: content-box;
                        }
                        input[type="submit"] {
                            display: none;
                        }
                        input:focus {
                            background-color: white;
                        }
                        svg {
                            position: absolute;
                            width: 25px;
                            height: auto;
                            top: 50%;
                            right: 1rem;
                            transform: translateY(-50%);
                        }
                        svg:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
            // show/hide
            opacity: 0;
            top: -99999px;
            left: -99999px;
            right: 99999px;
            transform: translateX(3rem);
            transition: opacity .3s ease-in-out, transform .3s ease-in-out, left 0s .3s, right 0s .3s, top 0s .3s;
            &.active {
                opacity: 1;
                top: 100%;
                left: 0;
                right: 0;
                transform: translateX(0);
                transition: opacity .3s ease-in-out, transform .3s ease-in-out;
            }
        }
        .hamburger {
            display: block;
            transition: all 0.3s ease-in-out;
        }
        .hamburger .line{
            width: 6.5rem;
            height: 7px;

            background-color: white;

            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
        .line {
            display: block;
            margin: 14px auto;
        }
        .hamburger:hover{
            cursor: pointer;
        }
        /* ONE */
        .hamburger.is-active .line:nth-child(2){
            opacity: 0;
        }
        .hamburger.is-active .line:nth-child(1){
            -webkit-transform: translateY(32px) rotate(45deg);
            -ms-transform: translateY(32px) rotate(45deg);
            -o-transform: translateY(32px) rotate(45deg);
            transform: translateY(22px) rotate(45deg);
        }
        .hamburger.is-active .line:nth-child(3){
            -webkit-transform: translateY(-10px) rotate(-45deg);
            -ms-transform: translateY(-10px) rotate(-45deg);
            -o-transform: translateY(-10px) rotate(-45deg);
            transform: translateY(-20px) rotate(-45deg);
        }
        &.header__hide {
            top: -16rem;
        }
    }
}

@media only screen and (max-width: 580px) {
    #header .top-header .wrapper {
        .main-logo {
            height: auto;
            width: 40%;
        }
    }
}

@media only screen and (max-width: 420px) {
    .content-body {
        padding-top: $mobileHeaderHeight;
    }
    #header {
        height: $mobileHeaderHeight;
        .top-header {
            height: $mobileHeaderHeight;
            .wrapper {
                width: calc(100% - 4rem);
                .main-logo {
                    display: block;
                    position: absolute;
                    width: 100%;
                    max-width: calc(100% - 210px);
                    top: 50%;
                    transform: translateY(-50%);
                    float: left;
                }
                .header-contact {
                    display: none;
                }
                // .donate-btn {
                //     // display: none;
                // }
            }
        }
        .bottom-header {
            height: calc(100vh - #{$mobileHeaderHeight});
            .wrapper {
                .links {
                    a {
                        padding: 2rem;
                    }
                    .sub-menu {
                        .sub-menu-wrap {
                            padding: 0 2rem;
                            width: calc(100% - 4rem);
                            a {
                                width: calc(100% - 2rem);
                            }
                        }
                    }
                }
            }
            .info {
                padding: 2rem 2rem 15rem;
            }
        }
        .hamburger {
            right: 2rem;
        }
        .hamburger .line{
            width: 6rem;
            height: 6px;
            background-color: white;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
        .line {
            display: block;
            margin: 12px auto;
        }
        .hamburger:hover{
            cursor: pointer;
        }
        /* ONE */
        .hamburger.is-active .line:nth-child(2){
            opacity: 0;
        }
        .hamburger.is-active .line:nth-child(1){
            -webkit-transform: translateY(17px) rotate(45deg);
            -ms-transform: translateY(17px) rotate(45deg);
            -o-transform: translateY(17px) rotate(45deg);
            transform: translateY(17px) rotate(45deg);
        }
        .hamburger.is-active .line:nth-child(3){
            -webkit-transform: translateY(-20px) rotate(-45deg);
            -ms-transform: translateY(-20px) rotate(-45deg);
            -o-transform: translateY(-20px) rotate(-45deg);
            transform: translateY(-20px) rotate(-45deg);
        }
        &.header__hide {
            top: -10rem;
        }
    }
}



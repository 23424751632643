.body {
    display: block;
    position: relative;

    margin-top: 9rem;
    margin-bottom: 9rem;

    width: 100%;
    height: auto;
}

:root {
    body {
      margin: 0;
      // Body font size 8px
      font-size: 1.6rem;
    }
  }

.section-wrap {
  width: calc(100% - 8rem);
  max-width: 138rem;
  padding: 9rem 4rem;
  margin: 0 auto;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

blockquote {
  @include body;
  border-left: solid 6px #ccc;
  padding-left: 3rem;
  margin-bottom: 4rem;
}

.visually-hidden {
    position: absolute !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden !important;
    clip: rect(1px 1px 1px 1px) !important; /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px) !important;
    white-space: nowrap !important; /* added line */
}

a {
  color: $purple;
}

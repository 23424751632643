/******************************************************************************************
COLORS
******************************************************************************************/

$darkOrange:    #ffcd00;
$lightOrange:   #fff5cc;
$purple:        #622281;
$lightPurple:   #EBE4EF;
$pink:          #ff3b50;
$lightBlue:     #fcfaf8;
$textBlack:     rgba(0, 0, 0, 0.9);

/******************************************************************************************
FONTS
******************************************************************************************/

$default: Helvetica, sans-serif;
$aleo: 'Aleo', serif;


/******************************************************************************************
MIXINS
******************************************************************************************/

@mixin standfirst {
    font-family: $default;
    font-size: 3.5rem;
    line-height: 4.5rem;
    font-weight: lighter;

    @media only screen and (max-width: 420px) {
        font-size: 3rem;
        line-height: 4.5rem;
    }
}

@mixin donate-btn {
    font-family: $default;
    font-size: 2.5rem;
    line-height: 4rem;
}

@mixin body {
    font-family: $default;
    font-size: 2.5rem;
    line-height: 4rem;
    color: $textBlack;

    @media only screen and (max-width: 420px) {
        font-size: 2.375rem;
        line-height: 3.5rem;
    }
}

@mixin heading {
    font-family: $aleo;
    font-size: 6.75rem;
    line-height: 8rem;
    color: $textBlack;

    @media only screen and (max-width: 720px) {
        font-size: 5.25rem;
        line-height: 6.25rem;
    }

    @media only screen and (max-width: 420px) {
        font-size: 4.25rem;
        line-height: 5rem;
    }

}

@mixin subheading {
    font-family: $aleo;
    font-size: 3rem;
    line-height: 4rem;
    color: $textBlack;
}

@mixin crossheading {
    font-family: $aleo;
    font-size: 4rem;
    line-height: 5rem;
    color: $textBlack;
}

@mixin subheadingLarge {
    font-family: $aleo;
    font-size: 5rem;
    line-height: 6rem;
    color: $textBlack;
    letter-spacing: 0.02em;
    @media only screen and (max-width: 420px) {
        font-size: 3.25rem;
        line-height: 4.5rem;
    }
}

@mixin quote {
    font-family: $aleo;
    font-size: 5rem;
    line-height: 6rem;
    color: $purple;
    @media only screen and (max-width: 420px) {
        font-size: 3.25rem;
        line-height: 4.5rem;
    }
}

@mixin small {
    font-family: $default;
    font-size: 2.25rem;
    line-height: 3rem;
    color: $textBlack;
}

@mixin large {
    font-family: $default;
    font-size: 3.5rem;
    line-height: 5.6rem;
    color: $textBlack;

    @media only screen and (max-width: 420px) {
        font-size: 3rem;
        line-height: 4.8rem;
    }
}

@mixin heroText {
    font-family: $default;
    font-size: 4.75rem;
    line-height: 6rem;
    color: $textBlack;
}

@mixin visually-hidden {
    position: absolute !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden !important;
    clip: rect(1px 1px 1px 1px) !important; /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px) !important;
    white-space: nowrap !important; /* added line */
}

@mixin body-content-width {
    width: calc(100% - 4rem);
    max-width: 78rem;
    margin: 0 auto;
    @media only screen and (min-width: 421px) {
        width: calc(100% - 8rem);
    }
}



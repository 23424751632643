.standfirst {
    @include body-content-width;
    display: block;
    position: relative;
    height: auto;
    margin-bottom: 4rem;
    p {
        @include standfirst;
        font-weight: lighter;
        color: $textBlack;
    }
    &--wysiwyg {
        padding-bottom: 8rem;
        margin-bottom: 0;
        &:last-child {
            margin-bottom: 8rem;
        }
        h3, p {
            margin-bottom: 4rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
        h3 {
            @include standfirst;
            font-weight: lighter;
            color: $textBlack;
        }
        p {
            @include body;
            color: $textBlack;
            margin-bottom: 4rem;
            font-weight: lighter;
            list-style: unset;
        }
    }
}
@media only screen and (max-width: 420px) {
    .standfirst {
        width: calc(100% - 4rem);
    }
}
.people {
    display: block;
    position: relative;

    width: calc(100% - 8rem);
    max-width: 138rem;
    height: auto;

    margin: 0 auto;
    margin-bottom: 9rem;


  h2 {
    @include subheadingLarge();
  }

  .people-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 4rem;

    .person {
      width: auto;
      max-width: 42rem;
      padding: 0 2rem 6rem;

      @media screen and (max-width: 420px) {
        max-width: auto;
        padding: 0 0 4rem;
      }

      .person-image {
        width: 24rem;
        height: 24rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .person-info {
        transform: skewY(-4deg);
        transform-origin: 100%;
        padding-top: 4rem;
        margin-top: -3rem;
        background-color: white;

        * {
          transform: skewY(4deg);
        }
      }

      .person-name {
        @include large();
        font-weight: 700;
        margin-bottom: 0;

        a {
          text-decoration: underline;
          color: $purple;
        }
      }

      .person-title {
        @include body();
        margin-bottom: 2rem;
      }

      .person-bio {
        @include body();
        margin-bottom: 2rem;
      }

      .person-twitter {
        @include body();
        background: transparent url(/assets/img/icon-twitter-black.svg) center left no-repeat;
        background-size: 2rem 2rem;
        padding-left: 3rem;
        word-wrap: anywhere;
        overflow-wrap: anywhere;
      }

      .person-email {
        @include body();
        background: transparent url(/assets/img/icon-email-black.svg) center left no-repeat;
        background-size: 2rem 2rem;
        padding-left: 3rem;
        word-wrap: anywhere;
        overflow-wrap: anywhere;
      }

    }
  }
}

.hero {
    position: relative;
    padding-top: 0;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .hero-header {
        position: relative;
        &:before {
            content: '';
            display: block;
            position: absolute;
            opacity: 0.9;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $darkOrange;
        }
        .text-container {
            position: relative;
            @include body-content-width;
            padding-top: 8rem;
            padding-bottom: 8rem;
            h2 {
                @include standfirst;
                color: $textBlack;
                margin-bottom: 3rem;
                height: auto;
            }
            h1 {
                @include heading;
                height: auto;
            }
        }
    }
    
    &.video-hero {
        padding-top: 0;

        .video-embed iframe {
            display: block; // remove the space after the bottom of the video
            width: 100%;
            /* height = 100 * (9 / 16) = 56.25 */
            height: 56.25vw;
        }

        @media only screen and (min-width: 780px) {
            /* height = 100 * (9 / 16) = 56.25 */
            height: 56.25vw;

            .video-embed {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                pointer-events: none;
                overflow: hidden;
                background-color: black;
            }

            .video-embed iframe {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 100%;

                @media (min-aspect-ratio: 16/9) {
                    /* height = 100 * (9 / 16) = 56.25 */
                    height: 56.25vw;
                }
                @media (max-aspect-ratio: 16/9) {
                    /* width = 100 / (9 / 16) = 177.777777 */
                    width: 177.78vh;
                }
            }

            .hero-header {
                position: absolute;
                bottom: 0;
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 1201px) {
    .hero {
        padding-top: 60rem;
        .hero-header {
            .text-container {
                padding-top: 9%;
            }
            &:before {
                background-color: transparent;
                background-image: url(../img/bg-yellow.svg);
                background-position: center top;
                background-size: cover;
            }
        }
    }
}
@media only screen and (min-width: 1401px) {
    .hero {
        padding-top: 60rem;
        .hero-header {
            .text-container {
                padding-top: 15.75rem;
            }
        }
    }
}
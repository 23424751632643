.link-strip {
    display: block;
    position: relative;

    width: 100%;
    height: 63rem;

    background-color: $darkOrange;
    clip-path: polygon(0 12rem,100% 0,100% 100%,0 100%);

    .link-grid {
        display: grid;
        position: relative;

        width: calc(100% - 8rem);
        height: auto;
        max-width: 138rem;
        max-height: 33rem;

        margin: 0 auto;
        padding-top: 19rem;

        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 6rem;

        .link-block {
            display: grid;

            grid-template-rows: 18rem 5rem 5rem 5rem;
            justify-items: center;

            button {
                width: 100%;
                max-width: 560px;
                height: 14rem;

                background-color: $darkOrange;
                border: 2px solid $purple;
                border-radius: 1rem;

                @include subheadingLarge;
                color: $purple;

                -ms-grid-row: 1;
                -ms-grid-row-span: 1;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    width: 528px;
                }

                @supports (-ms-ime-align:auto) {
                    width: 528px;
                }

            }

            a {
                @include standfirst;
                display: block;
                color: $purple;
                text-decoration: underline;
                text-decoration-color: $purple;
                font-weight: bold;
                text-align: center;
                margin-bottom: 1rem;
            }

            .button {
                @include subheadingLarge;
                display: block;
                position: relative;

                height: 76px;
                width: 524px;

                @media screen and (max-width: 640px) {
                    width: auto;
                    padding-left: 4rem;
                    padding-right: 4rem;
                }

                @media screen and (max-width: 420px) {
                    height: 66px;
                }

                padding-top: 4rem;
                border: 2px solid $purple;
                border-radius: 1rem;
                background-color: $darkOrange;

                color: $purple;
                text-decoration: none;

                &:hover,
                &:focus {
                    background-color: $purple;
                    color: white;
                }
            }

            a:nth-of-type(1) {
                -ms-grid-row: 2;
                -ms-grid-row-span: 1;
            }

            a:nth-of-type(2) {
                -ms-grid-row: 3;
                -ms-grid-row-span: 1;
            }

            a:nth-of-type(3) {
                -ms-grid-row: 4;
                -ms-grid-row-span: 1;
            }
        }

        .link-block:nth-of-type(1) {
            -ms-grid-column: 1;
            -ms-grid-column-span: 1;
        }

        .link-block:nth-of-type(2) {
            -ms-grid-column: 2;
            -ms-grid-column-span: 1;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .link-block:nth-of-type(1) {
                margin-right: 6rem;
            }
        }

       @supports (-ms-ime-align:auto) {
            .link-block:nth-of-type(1) {
                margin-right: 6rem;
            }
        }
    }
}


@media only screen and (max-width: 1140px) {
    .link-strip {
        height: auto;
        padding-bottom: 9rem;

        .link-grid {
            width: calc(100% - 4rem);
            height: auto;
            max-width: 66rem;
            max-height: unset;

            margin: 0 auto;
            padding-top: 19rem;

            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
            grid-row-gap: 6rem;
        }
    }
}

@media only screen and (max-width: 640px) {
    .link-strip {
        .link-grid {
            .link-block {
                a {
                    font-size: 3rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .link-strip {
        .link-grid {
            .link-block {
                a {
                    font-size: 2.3rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    .link-strip {
        .link-grid {
            .link-block {
                a {
                    font-size: 1.9rem;
                }
            }
        }
    }
}


.related {
    display: block;
    position: relative;
    text-align: center;

    width: calc(100% - 8rem);
    max-width: 138rem;
    height: auto;

    margin: 0 auto;
    padding-bottom: 9rem;

    .related-grid {
        display: grid;
        position: relative;

        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 6rem;


        .img-wrapper {

            position: relative;
            width: 100%;
            padding-top: calc(17 / 21 * 100%);
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .related-info {
            display: block;
            position: relative;

            width: 100%;
            height: auto;

            background-color: $lightOrange;
            text-align: left;

            p {
                @include small;

                padding-left: 3rem;
                padding-top: 4rem;

                text-transform: capitalize;
                font-weight: lighter;
            }

            h2 {
                @include standfirst;

                padding-left: 3rem;
                padding-bottom: 4rem;

                color: $purple;
                font-weight: normal;
                text-decoration: underline;
            }
        }
    }
}

@media only screen and (max-width: 780px) {
    .related {
        .related-grid {
            display: grid;
            position: relative;
    
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            grid-row-gap: 6rem;
        }
    }
    
}

@media only screen and (max-width: 420px) {
    .related {
        width: calc(100% - 4rem);
    }
}



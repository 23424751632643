.hero-home {
    display: block;
    position: relative;

    width: 100%;
    height: 100rem;
    overflow: hidden;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .hero-text-block {
        display: block;
        position: absolute;

        width: 78rem;
        height: auto;

        background-color: $darkOrange;
        padding: 6rem 7.25rem;
        padding-bottom: 9rem;

        top: 43rem;
        left: 15.5%;

        clip-path: polygon(0 0,100% 0,100% 100%,0 calc(100% - 3rem));

        h2 p {
            @include heroText;
            margin-bottom: 2rem;
            font-weight: bold;
        }

        p {
            @include heroText;
            font-weight: lighter;
        }

        a {
            display: inline-block;
            position: relative;

            padding: 1rem 2rem;
            margin-top: 2rem;
            margin-bottom: 2rem;

            @include body;

            background-color: $darkOrange;
            color: $purple;
            border: 2px solid $purple;
            border-radius: 1rem;

            &:hover,
            &:focus {
                background-color: $purple;
                color: white;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .hero-home {

        .hero-text-block {
            display: block;
            position: absolute;

            width: 78rem;
            height: auto;

            background-color: $darkOrange;
            padding: 6rem 7.25rem;
            padding-bottom: 9rem;

            left: 50%;
            transform: translateX(-50%);

        }
    }

}

@media only screen and (max-width: 780px) {
    .hero-home {
        width: 100%;
        height: auto;
        padding-top: 50%;
        background-size: contain;
        background-position: top;

        overflow: visible;

        .hero-text-block {
            display: block;
            position: relative;

            width: calc(100% - 4rem);
            height: auto;

            background-color: $darkOrange;
            padding: 4rem 2rem;
            padding-bottom: 9rem;

            top: -1rem;
            left: 0;
            transform: translateX(0);

        }

    }

}

.quote {
    @include body-content-width;
    display: block;
    position: relative;
    text-align: center;
    height: auto;
    margin-bottom: 9rem;
    .quote-icon {
        display: inline;
        width: 5.75rem;
        height: auto;
    }
    p {
        display: inline;
        @include quote;
    }
}
@media only screen and (max-width: 420px) {
    .quote {
        width: calc(100% - 4rem);
    }
}
.section__404 {
    background-color: $darkOrange;
    margin-bottom: 9.1rem;
    @include body;
    img {
        max-width: 100%;
    }
    .img-wrap {
        position: relative;
        .img-skew {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 20%;
            width: 100%;
            background-color: $darkOrange;
            transform: skewY(-3deg);
            transform-origin: right top;
        }
    }
    .button {
        display: inline-block;
        position: relative;
        padding: 1rem 2rem;
        margin-bottom: 2rem;
        @include body;
        font-weight: 400;
        background-color: $darkOrange;
        color: $purple;
        border: 2px solid $purple;
        border-radius: 1rem;
        &:hover,
        &:focus {
            background-color: $purple;
            color: white;
        }
    }
    .column {
        text-align: center;
        margin-bottom: 0;
        p {
            a {
                text-decoration: underline;
            }
        }
    }
    .row {
        width: calc(100% - 8rem);
        max-width: 138rem;
        height: auto;
        margin: 0 auto;
        padding-bottom: 6rem;
        display: flex;
        justify-content: space-evenly;
        .col__6 {
            flex: 1 1 calc(50% - 6rem);
            max-width: 400px;
        }
    }
    @media only screen and (max-width: 720px) {
        .column {
            margin-bottom: 3rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .row {
            display: block;
            padding-bottom: 4rem;
            .col__6 {
                max-width: none;
            }
        }
    }
}
.quote-banner {
    display: grid;
    position: relative;

    width: 100%;
    height: 68rem;

    z-index: 1;

    grid-template-columns: minmax(auto, 48.5%) auto;
    grid-template-rows: 1fr;

    background-color: $lightPurple;
    // overflow: hidden;
    transform: skewY(2deg);

    .left-col {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .right-col {
        max-width: 78rem;
        text-align: center;
        align-self: center;
        transform: skewY(-2deg);

        width: calc(100% - 12rem);
        padding-left: 6rem;
        padding-right: 6rem;

        display: flex;

        .quote-icon {
            display: inline-block;
            width: 5.75rem;
            height: auto;
            flex-grow: 0;
            flex-shrink: 0;

            &:first-child {
                align-self: flex-start;
                margin-right: 1em;
            }

            &:last-child {
                align-self: flex-end;
            }
        }

        p {
            flex-grow: 1;
            @include quote;
        }
    }
}

.banner-overflow {
    display: block;
    position: relative;

    width: 100%;
    height: 0;
    padding: 4% 0 40px;

    z-index: 1;
    background-color: white;
    margin: -2% 0 0;

    & + .newsletter {
        margin-top: 0;
    }
}

@media only screen and (max-width: 780px) {
    .quote-banner {
        display: grid;
        position: relative;

        width: 100%;
        height: auto;

        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-row-gap: 6rem;

        background-color: $lightPurple;
        overflow: hidden;
        transform: skewY(2deg);

        .left-col {
            width: calc(100%);
            padding: 50% 0 0;
        }

        .right-col {
            width: calc(100% - 4rem);
            padding-left: 2rem;
            padding-right: 2rem;
            padding-bottom: 6rem;
        }
    }

    .banner-overflow {
        display: block;
        position: relative;

        width: 100%;
        height: 10rem;

        z-index: 1;
        background-color: white;
        top: -1rem;
    }
}
